import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Layout from '../components/Layout';
import ArticleGrid from '../components/ArticleGrid';
import SliceZone from '../components/SliceZone';
import '../scss/news.scss';

export default function NewsTemplate ({ data, path }) {
  const [ category, setCategory ] = useState('');
  const [ loadCategory, setLoadCategory ] = useState(false);

  const filterCategory = (ev) => {
    setCategory(ev.target.value);
    setLoadCategory(true);
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '8.5rem'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

  const classes = useStyles();

  const allNews = data.allPrismicArticle.edges;
  const [ list, setList ] = useState([ ...allNews.slice(0, 5) ]);
  const [ filteredList, setFilteredList ] = useState([ ...allNews.slice(0, 5) ]);
  const [ loadMore, setLoadMore ] = useState(false);
  const [ hasMore, setHasMore ] = useState(allNews.length > 5);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allNews.length;
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 5)
        : [];
      setList([ ...list, ...nextResults ]);
      setLoadMore(false);
      setLoadCategory(true);
    }
    }, [loadMore, hasMore]) //eslint-disable-line

  useEffect(() => {
    const isMore = list.length < allNews.length;
    setHasMore(isMore);
    }, [list]) //eslint-disable-line

  useEffect(() => {
    const tmp = list.filter((item) => {
      if (item.node.data.category === category) {
        return item;
      } else if (category === '') {
        return item;
      }
    });
    setFilteredList(tmp);
    setLoadCategory(false);
    }, [loadCategory]) //eslint-disable-line

  // Array of all news articles
  const hero = allNews[0].node.data;
  const prismicNewsPage = data.prismicNewsPage.data;

  const unique = allNews.map(item => item.node.data.category)
    .filter((value, index, _self) => _self.indexOf(value) === index);

  return (
    <Layout path={path}>
      <Helmet>
        <title>{prismicNewsPage.title.text}</title>
        <meta name="description" content={prismicNewsPage.meta_description?.text} />
        <meta name="og:title" content={prismicNewsPage.title.text} />
        <meta name="og:description" content={prismicNewsPage.meta_description?.text} />
        {/*<meta name="og:image" content={ogImage?.fixed?.src} />*/}
      </Helmet>

      <main className={`container container-news`}>

        <section className={`news-hero`}>
          <GatsbyImage
            className='hero-img'
            layout="fullWidth"
            image={getImage(hero.hero_background)}
            alt={hero.hero_background.alt ?? ''}
            placeholder="blurred"
            loading="eager"
          />
          <div className={`hero-content `}>
            <div className={`category`}>{hero.category}</div>
            <RichText render={hero.title.raw}/>
            <div>By: {hero.author}</div>
            <p>{hero.body[0].primary.content.raw[0].text}</p>
            <Link to={hero.slug} className={`btn button`}>Read</Link>
          </div>
        </section>

        <section className={`main-news-articles`}>
          <div className={`article-tools`}>
            <h2 className={`subhead01`}>{prismicNewsPage.title.text}</h2>

            <div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">All Sections</InputLabel>
                <Select
                  labelId="article-category-select"
                  id="article-category-select"
                  value={category}
                  onChange={filterCategory}
                  label="Category"
                >
                  <MenuItem value="">
                    <em>All sections</em>
                  </MenuItem>
                  {unique.map((item, index) => {
                    return (
                      <MenuItem key={`option-${index}`} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>

          <ArticleGrid articles={filteredList.slice(0, 5)} cls={``}/>

          <SliceZone sliceZone={prismicNewsPage.body}/>

          {list.length > 5 &&
              <ArticleGrid articles={list.slice(5, filteredList.length)} cls={``}/>
          }

          { hasMore &&
              <button className={`button load-more`} onClick={handleLoadMore}>Load more articles</button>
          }
        </section>
      </main>
    </Layout>
  );
}

export const query = graphql`
query NewsPageQuery ($id: String!) {
  prismicNewsPage(id: {eq: $id}) {
    _previewable

    data {
      title { text }
      meta_description { text }

      body {
        ... on PrismicNewsPageDataBodyConnectModule {
          ...NewsPageDataBodyConnectModule
        }
      }
    }
  }

  allPrismicArticle(
    sort: { order: DESC, fields: [ data___publish_date, last_publication_date ] }
  ) {
    edges {
      node {
        ...articleGrid
      }
    }
  },
}
`;
