import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ArticleGrid ({ articles, cls }) {
  return (
    <div className={`article-grid ${cls}`}>
      {articles.map((item, index) => {
        const teaser = item.node.data.body[0].primary.content.raw.filter(obj => {
          return obj.type === 'paragraph';
        })[0].text;
        return (
          <div key={`article-${index}`} className={`article`}>
            <GatsbyImage
              className='article-hero'
              image={getImage(item.node.data.hero_background)}
              alt={item.node.data.hero_background.alt ?? ''}
              placeholder="blurred"
              loading="lazy"
            />
            <div>{item.node.data.category}</div>
            <h3 className={`subhead01`}>{item.node.data.title.raw[0].text}</h3>
            <div>By: {item.node.data.author}</div>
            <p>{teaser}</p>
            <div>{item.node.data.read_time}</div>
            <Link to={item.node.data.slug} className={``}>Read more &gt;</Link>
          </div>
        );
      })}
    </div>
  );
}

export const query = graphql`
fragment articleGrid on PrismicArticle {
  _previewable
  id

  data {
    title { raw }

    slug, author, category, read_time

    body {
      ... on PrismicArticleDataBodyArticleContent {
        id
        primary {
          content { raw }
        }
      }
    }

    hero_background { alt, gatsbyImageData }
  }
}
`;
