import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Layout from '../components/Layout';
import { LiquidAndLogoAnimat } from '../slices/liquidAndLogoAnimat';
import '../scss/careerPosition.scss';

export default function CareerPositionTemplate ({ data, path }) {
  if (!data) return null;

  const doc = data.prismicCareerPosition.data;

  let animation = null;
  if (doc.animation.document) {
    animation = ( <LiquidAndLogoAnimat slice={doc.animation.document} /> );
  }

  return (
    <Layout path={path}>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="description" content={doc.meta_description?.text} />
        <meta name="og:title" content={doc.title.text} />
        <meta name="og:description" content={doc.meta_description?.text} />
        {/*<meta name="og:image" content={ogImage?.fixed?.src} />*/}
      </Helmet>

      <main className={`container container-career`}>
        <section className={`career-wrapper flex-column`}>
          <div className={`career-content`}>
            <RichText render={doc.title.raw}/>

            <div className={`career-description`}>
              <div className={`flex-column`}>
                <div className={`iconlabel`}>Location:</div>
                <div className={`subhead01`}>{doc.location}</div>
              </div>
              <div className={`flex-column`}>
                <div className={`iconlabel`}>Position:</div>
                <div className={`subhead01`}>{doc.position}</div>
              </div>
            </div>
            <RichText render={doc.content.raw}/>
            <a href="mailto:jobs@galliumstudios.com" className="button">{doc.cta_text}</a>
          </div>
          {animation}
        </section>
      </main>
    </Layout>
  );
}

export const query = graphql`
query CareerPositionQuery ($id: String!) {
  prismicCareerPosition(id: {eq: $id}) {
    _previewable

    data {
      title { raw }
      meta_description { text }
      location, position, cta_text
      content { raw }

      animation {
        document {
          ... on PrismicLiquidAndLogoAnimat {
            ...liquidAndLogoAnimation
          }
        }
      }
    }
  }
}
`;
