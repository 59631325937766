/*globals process*/
import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrismicUnpublishedPreview, componentResolverFromMap }
  from 'gatsby-plugin-prismic-previews';
import Layout from '../components/Layout';
import { linkResolver } from '../utils/linkResolver';

import ArticleTemplate from '../templates/article';
import CareerPositionTemplate from '../templates/careerPosition';
import NewsPageTemplate from '../templates/news';
import PageTemplate from '../templates/pages';

function NotFoundPage () {
  return (
    <Layout>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <main className="container">
        <h1>Oh no!</h1>
        <h3>We can&apos;t seem to find the page you&apos;re looking for.</h3>
        <br />
      </main>
    </Layout>
  );
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver,
    componentResolver: componentResolverFromMap({
      article: ArticleTemplate,
      career_position: CareerPositionTemplate,
      news_page: NewsPageTemplate,
      page: PageTemplate
    })
  }
]);
