/*globals process*/
import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import { linkResolver } from '../utils/linkResolver';

function PageTemplate ({ data, path }) {
  const doc = data.prismicPage.data;

  return (
    <Layout path={path}>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="description" content={doc.meta_description?.text} />
        <meta name="og:title" content={doc.title.text} />
        <meta name="og:description" content={doc.meta_description?.text} />
        {/*<meta name="og:image" content={ogImage?.fixed?.src} />*/}
      </Helmet>
      <main className="container">
        <SliceZone sliceZone={doc.body} />
      </main>
    </Layout>
  );
}

export const query = graphql `
query PageQuery($id: String) {
  prismicPage(id: { eq: $id }) {
    _previewable

    data {
      title { text }
      meta_description { text }

      body {
        __typename
        ... on PrismicPageDataBodyAddressBlock {
          ...PageDataBodyAddressBlock
        }
        ... on PrismicPageDataBodyArticleContent {
          ...PageDataBodyArticleContent
        }
        ... on PrismicPageDataBodyCareersOpenPositions {
          ...PageDataBodyCareersOpenPositions
        }
        ... on PrismicPageDataBodyConnectModule {
          ...PageDataBodyConnectModule
        }
        ... on PrismicPageDataBodyContactUsForm {
          ...PageDataBodyContactUsForm
        }
        ... on PrismicPageDataBodyThreeColumnGridWithIcons {
          ...PageDataBodyThreeColumnGridWithIcons
        }
        ... on PrismicPageDataBodyTheTeam {
          ...PageDataBodyTheTeam
        }
        ... on PrismicPageDataBodyTabbedSection {
          ...PageDataBodyTabbedSection
        }
        ... on PrismicPageDataBodySimpleContent {
          ...PageDataBodySimpleContent
        }
        ... on PrismicPageDataBodyJoinTheCommunity {
          ...PageDataBodyJoinTheCommunity
        }
        ... on PrismicPageDataBodyIntroducingProxi {
          ...PageDataBodyIntroducingProxi
        }
        ... on PrismicPageDataBodyHeader {
          ...PageDataBodyHeader
        }
        ... on PrismicPageDataBodyDevelopmentRoadmap {
          ...PageDataBodyDevelopmentRoadmap
        }
        ... on PrismicPageDataBodyContentPacks {
          ...PageDataBodyContentPacks
        }
        ... on PrismicPageDataBodyFeaturesBlocks {
          ...PageDataBodyFeaturesBlocks
        }

      }
    }
  }
}
`;

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver
  }
]);
